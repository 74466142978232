<!--考勤管理页面日度表格  -->
<template>
  <div class="">
    <!-- table模块 -->
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      :searchActions="searchActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="true"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @export="expor"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
    </ax-table>
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "请输入姓名" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "部门",
    type: "select",
    model: "cellName",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "时间",
    type: "rangePicker",
    model: "rangePicker",
    options: { showTime: true },
  },
];
import api from "../api";
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      api,
      columns: this.$ax.tools.initColumn([
        {
          title: "时间",
          dataIndex: "residentName",
          ellipsis: false,
          width: 120,
        },
        {
          title: "姓名",
          dataIndex: "gender",
          ellipsis: false,
          width: 80,
        },
        {
          title: "部门",
          dataIndex: "identityCard",
          ellipsis: true,
          width: 190,
        },
        {
          title: "职务",
          dataIndex: "age",
          ellipsis: false,
          width: 70,
        },
        {
          title: "所属规则",
          dataIndex: "nation_dictText",
          ellipsis: true,
          width: 100,
        },

        {
          title: "班次",
          dataIndex: "educationalLevel_dictText",
          ellipsis: false,
          width: 120,
        },
        {
          title: "最早",
          dataIndex: "politicalStatus_dictText",
          ellipsis: true,
          width: 150,
        },

        {
          title: "最晚",
          dataIndex: "maritalStatus_dictText",
          ellipsis: false,
          width: 100,
        },
        {
          title: "打卡次数",
          dataIndex: "job_dictText",
          ellipsis: false,
          width: 120,
        },
        {
          title: "标准工作时长",
          dataIndex: "birthDate",
          ellipsis: false,
          width: 120,
        },
        {
          title: "标准状态",
          dataIndex: "currentAreaCode",
          ellipsis: false,
          width: 120,
        },
      ]),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "searchlist", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [{ name: "export", text: "导出筛选", type: "#67C23A" }],
      selectedRowKeys: [],
      dataSourceParams: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 导出
    expor() {
      api.export({
        fileName: "楼栋管理.xls",
        params: "",
      });
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-menu-item-selected {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}

/deep/.ant-menu-item:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #f6ffed;
  color: #389e0d;
  border-radius: 4px;
  border: #f6ffed;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>